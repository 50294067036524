/*.navbar{
    width: 100%;
    height: 16vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-logo-container{
    max-height: 100%;
    max-width: 20%;
    height: 100%;
    width: 20%;
}
.logo{
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}
.navbar-content-container{
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
*/
:root {
    --navbar-bg-color: hsl(0, 0%, 15%);
    --navbar-text-color: hsl(0, 0%, 85%);
    --navbar-text-color-focus: white;
    --navbar-bg-contrast: hsl(0, 0%, 25%);
  }
  .container {
    max-width: 1420px;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    margin-left: auto;
    margin-right: auto;
  }
  #navbar {
    --navbar-height: 74px;
    position: fixed;
    height: var(--navbar-height);
    background-color: var(--navbar-bg-color);
    left: 0;
    right: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    z-index: 1030;
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
  .navbar-item {
    margin: 0.4em;
    width: 100%;
  }
  
  .home-link,
  .navbar-link {
    color: var(--navbar-text-color);
    text-decoration: none;
    display: flex;
    font-weight: 500;
    align-items: center;
    font-size: 1.3rem;
  }
  
  .home-link:is(:focus, :hover) {
    color: var(--navbar-text-color-focus);
  }
  
  .navbar-link {
    justify-content: center;
    width: 100%;
    padding: 0.4em 0.8em;
    border-radius: 5px;
  }
  
  .navbar-link:is(:focus, :hover) {
    color: var(--navbar-text-color-focus);
    background-color: var(--navbar-bg-contrast);
  }

  .active{
    background: url("../img/DampfendeWildsau-Logo-SW-201007.svg") no-repeat;
    background-size: cover;
    background-position: 0 -28px;
    font-size: 1.8rem;
  }
  .navbar-logo {
    background: url('../img/Logo-Hellblau.svg') no-repeat;
    background-size: cover;
    border-radius: 50%;
    width: 76px;
    height: 76px;
    margin-right: 0.5em;
  }
  @media screen and (max-width: 425px){
    .navbar-logo{
      width: 56px;
      height: 56px;
      margin-left: -1em;
      padding-right: 0.5em;
      margin-right: 0;
    }
    .home-link{
      font-size: 1rem;
    }
  }
  #navbar-toggle {
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .icon-bar {
    display: block;
    width: 25px;
    height: 4px;
    margin: 2px;
    background-color: var(--navbar-text-color);
  }
  #navbar-toggle:is(:focus, :hover) .icon-bar {
    background-color: var(--navbar-text-color-focus);
  }
  #navbar-toggle[aria-expanded="true"] .icon-bar:is(:first-child, :last-child) {
    position: absolute;
    margin: 0;
    width: 30px;
  }
  #navbar-toggle[aria-expanded="true"] .icon-bar:first-child {
    transform: rotate(45deg);
  }
  #navbar-toggle[aria-expanded="true"] .icon-bar:nth-child(2) {
    opacity: 0;
  }
  #navbar-toggle[aria-expanded="true"] .icon-bar:last-child {
    transform: rotate(-45deg);
  }
  #navbar-menu {
    position: fixed;
    top: var(--navbar-height);
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    left: 0;
    right: 0;
  }
  #navbar-toggle[aria-expanded="true"] + #navbar-menu {
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    visibility: visible;
  }
  .navbar-links {
    list-style: none;
    position: absolute;
    background-color: var(--navbar-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    margin: 1.4rem;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  #navbar-toggle[aria-expanded="true"] + #navbar-menu .navbar-links {
    padding: 1em;
  }
  @media screen and (min-width: 700px) {
    #navbar-toggle,
    #navbar-toggle[aria-expanded="true"] {
      display: none;
    }
  
    #navbar-menu,
    #navbar-toggle[aria-expanded="true"] #navbar-menu {
      visibility: visible;
      opacity: 1;
      position: static;
      display: block;
      height: 100%;
    }
  
    .navbar-links,
    #navbar-toggle[aria-expanded="true"] #navbar-menu .navbar-links {
      margin: 0;
      padding: 0;
      box-shadow: none;
      position: static;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }
  }
  