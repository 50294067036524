* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "BN-Norplay", sans-serif;
}

html, body{
  overflow-x: hidden;
  scroll-behavior:auto;
}

.hero{
  background-color: black;
  max-width: 100vw;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.about{
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  background: url('./images/imagesLens/Reduced_Gruppenbild_alle.jpg') no-repeat;
  background-size: cover;
  background-position: 0 50px;
  perspective: 10px;
  scroll-behavior: smooth; 
}
@media only screen and (max-width: 425px){
  .about{
    height: calc(100vh - 56px);
    background-size: contain;
    background-color: black;
    background-position: 0 300px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.container {
  max-width: 1420px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  margin-left: auto;
  margin-right: auto;
}

.galerie{
  padding-top: 74px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #eee;
}

.contact{
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 100px);
}

.footer{
  width: 100%;
  position: relative;
  height: 16vh;
}
.footerVideo{
  position: absolute;
  width: 100%;
  height: 16vh;
  object-fit: cover;
  z-index: -1;
}
.footerContent{
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;

}

.content{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.text-container{
  padding-top: 100px;
  min-height: calc(100vh - 100px);
  width: 100%;
  margin: auto;
}
.text-container > a {
  color: blue;
}

