.galerieContainer{
    min-height: 94vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: url('../images/DarkBackground.jpg');
    padding-bottom: 1em;
}
.galerieContent{
    flex: 1;
    width: 100%;
    background-color: transparent;
    padding: 0.5em;
    padding-top: 5em;
    border-radius: 5px;
}

.flex-start{
    display: flex;
    justify-content: flex-start;
}
.flex-end{
    display: flex;
    justify-content: flex-end;
}
.flex-center{
    display: flex;
    justify-content: center;
}
.margin-left-auto{
    margin-left: auto;
}
.margin-right-auto{
    margin-right: auto;
}
.negative-margin-left{
    margin-left: -100px;
}
.negative-margin-right{
    margin-right: -160px;
}
.margin-top{
    margin-top: -60px;
}
.image-center-on-click{
    position: absolute;
    left: 30%;
    top: 30%;
}

.rounded{
    border-radius: 10px;
}

.loadMoreButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background: transparent;
}
/*
.loadMoreButtonContainer > button {
    align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}
.loadMoreButtonContainer > button:active, button:focus{
    outline: none;
}
.loadMoreButtonContainer > button:hover{
    background-position: -20px -20px;
}

.loadMoreButtonContainer > button:focus:not(:active) {
    box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}
*/


.smokeBodyGalerie{
    filter: url('#filter');
}

.smokeFilterGalerie{
    min-width: 100%;
    width: 100%;
    height: 18vh;
    margin: auto;
    background-clip: text;
    background: #ff00cc;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #333399, #ff00cc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #333399, #ff00cc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    font-size: 12vmin;
    text-align: center;
    line-height: 16vh;
    filter: blur(2px) contrast(120%);
    text-shadow: 6px 6px 0px rgba(0,0,0,0.2);
}


  .smokeHeaderGalerie {
    height: 140px;
    width: 100%;
    margin-bottom: 1em;
  }




.centered-image-container{
    height: 80vh;
    width: 60vw;
    position: fixed;
    top: 120px;
    left: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: #000000e0;
    /*background: url('../images/DarkBackground.jpg');*/
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    z-index: 99;
}
.centered-image{
    width: 100%;
    height: 90%;
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}
.centered-image > img{
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.image-description{
    position: absolute;
    bottom: 10%;
    height: 10%;
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.description-container{
    min-height: 50%;
    height: 70%;
    min-width: 90%;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 2.5rem;
    line-height: 2.5em;
    border-radius: 10px;
    padding-left: 0.7em;
    font-weight: bold;
    color: white;
}

.image-slider-image{
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
}


.image-slider-description{
    min-height: 72%;
    height: 72%;
    min-width: 50%;
    width: 50%;
    margin-right: 150px;
    margin-left: -425px;
    border: 1px solid white;
}


/* Gallerie*/

.image-gallery{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
/*
.image-gallery::after{
    content: "";
    flex-grow: 999;
}
*/
.image-gallery > li{
    height: 300px;
    cursor: pointer;
    position: relative;
    list-style: none;
    flex-grow: 1;
}
.image-gallery > li:hover{
    transition: transform 500ms ease-in-out;
    z-index: 2;
    border: 1px solid white;
    border-radius: 5px;
}

.imageSize{
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px;
}
.imageSize:hover{
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    cursor: pointer;
}

/* ==================== FONTS =========================== */
@font-face {
    font-family: 'Gover';
    src: local('GOVER'), url('../fonts/GOVER.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Campfire';
    src: local('CAMPFIRE'), url('../fonts/Campfire-trial.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hangout';
    src: local('HANGOUT'), url('../fonts/Hangout-trial.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nordplay';
    src: local('BN-Nordplay'), url('../fonts/BN-Norplay.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nordplay';
    src: local('BN-Nordplay'), url('../fonts/BN-Norplay\ Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Nordplay';
    src: local('BN-Nordplay'), url('../fonts/BN-Norplay\ Light.otf') format('opentype');
    font-weight: lighter;
    font-style: normal;
}
.font-face-gover{
    font-family: 'Gover';
}
.font-face-campfire{
    font-family: 'Campfire';
}
.font-face-hangout{
    font-family: 'Hangout';
}
.font-face-nordplay{
    font-family: 'Nordplay';
}
.font-face-nordplay-bold{
    font-family: 'Nordplay';
    font-weight: bold;
}
.font-face-nordplay-light{
    font-family: 'Nordplay';
    font-weight: lighter;
}





.close {
    width: 40px;
    height: 40px;
    position: relative;
    background: #455a64;
    border-radius: 50%;
    box-shadow: 0 0 20px -5px rgba(255, 255, 255, .5);
    transition: 0.25s ease-in-out;
    cursor: pointer;
    animation: fade-in 0.5s ease-out 0.25s both;
}
@keyframes fade-in {
    from {
        opacity: 0;
        transform: scale(1.1);
   }
}
.close .circle path {
    stroke: rgba(255, 255, 255, 1);
    fill: none;
    stroke-width: 1px;
    stroke-linecap: round;
    animation: progress 0.5s ease-out 0.25s both;
}
@keyframes progress {
    from {
        stroke-dasharray: 0 100;
   }
}
.close span {
    display: block;
    width: 13px;
    height: 2px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px -5px rgba(255, 255, 255, .5);
    border-radius: 20px;
    position: absolute;
    transition: 0.25s ease-in-out;
    animation: slide-in 0.5s ease-in-out 0.25s both;
}
@keyframes slide-in {
    from {
        width: 0;
   }
}
.close span:nth-child(1) {
    top: 19px;
    left: 20px;
    transform: rotate(45deg);
    transform-origin: top left;
}
.close span:nth-child(2) {
    top: 19px;
    right: 20px;
    transform: rotate(-45deg);
    transform-origin: top right;
}
.close span:nth-child(3) {
    bottom: 19px;
    left: 20px;
    transform: rotate(-45deg);
    transform-origin: bottom left;
}
.close span:nth-child(4) {
    bottom: 19px;
    right: 20px;
    transform: rotate(45deg);
    transform-origin: bottom right;
}
.close:hover {
    background: #37474f;
}
.close:hover span {
    width: 15px;
}


/* BUTTON GLOW */
.glow-on-hover {
    width: 20%;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 1.5rem;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #fff
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@media only screen and (max-width: 425px){
    .glow-on-hover{
        width: 50%;
    }
}