@font-face {
    font-family: 'Gover';
    src: local('GOVER'), url('../fonts/GOVER.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Campfire';
    src: local('CAMPFIRE'), url('../fonts/Campfire-trial.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hangout';
    src: local('HANGOUT'), url('../fonts/Hangout-trial.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nordplay';
    src: local('BN-Nordplay'), url('../fonts/BN-Norplay.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Nordplay';
    src: local('BN-Nordplay'), url('../fonts/BN-Norplay\ Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Nordplay';
    src: local('BN-Nordplay'), url('../fonts/BN-Norplay\ Light.otf') format('opentype');
    font-weight: lighter;
    font-style: normal;
}
.font-face-gover{
    font-family: 'Gover';
}
.font-face-campfire{
    font-family: 'Campfire';
}
.font-face-hangout{
    font-family: 'Hangout';
}
.font-face-nordplay{
    font-family: 'Nordplay';
}
.font-face-nordplay-bold{
    font-family: 'Nordplay';
    font-weight: bold;
}
.font-face-nordplay-light{
    font-family: 'Nordplay';
    font-weight: lighter;
}






/* ======================================================================== */
/* NewStyle */

.title{
    font-size: 3rem;
    color: white;
    text-shadow: 0 0 5px black;
    transform: translateZ(5px) scale(1.5);
    margin-top: 250px;
}
header {
    margin-top: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform-style: preserve-3d; 
    z-index: -1;
}
.background {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    transform: translateZ(-20px) scale(4);
}
.foreground {
    padding-right: 5em;
    position: absolute;
    height: auto;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    transform: translateZ(-10px) scale(2.25);
}
.ending-group-image {
    object-fit: contain;
    z-index: -1;
    height: 100%;
    width: 100%;
    padding-top: 80px;
    border-radius: 10px;
}
section {
    font-size: 1.5rem;
    padding: 2rem;
    background-color: hsl(0, 0%, 15%);
    color: white;
}

.about-description {
    max-width: 700px;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.placeholder{
    min-height: 200vh;
    min-width: 100%;
    background-color: transparent;
}
.text{
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1.4rem;
    width: 80%;
    height: 80%;
    letter-spacing: 1px;
    line-height: 175%;
}
.text > a{
    font-size: 1.8rem;
    color: rgb(14, 150, 173);
}
.soundcloud{
    padding: 1.4rem;
    min-height: 300px;
    background: url('../images/ControllerWithScreen.png') no-repeat;
    width: 100%;
    background-position: 50% 0;
    display: flex;
    justify-content: center;
    align-items: center;
}




.wrapper-soundcloud-button {
    display: flex;
    justify-content: center;
  }
  
  .soundcloud-button {
      display: flex;
      padding: 10px 45px;
      text-decoration: none;
      font-family: 'Poppins', sans-serif;
      font-size: 40px;
      color: white;
      background: #6225E6;
      transition: 1s;
      box-shadow: 6px 6px 0 black;
      transform: skewX(-15deg);
  }
  
  .soundcloud-button:focus {
     outline: none; 
  }
  
  .soundcloud-button:hover {
      transition: 0.5s;
      box-shadow: 10px 10px 0 #FBC638;
  }
  
  .soundcloud-button span:nth-child(2) {
      transition: 0.5s;
      margin-right: 0px;
  }
  
  .soundcloud-button:hover  span:nth-child(2) {
      transition: 0.5s;
      margin-right: 45px;
  }
  
    .soundcloud-button.span {
      transform: skewX(15deg) 
    }
  
    .soundcloud-button.span:nth-child(2) {
      width: 20px;
      margin-left: 30px;
      position: relative;
      top: 12%;
    }
    
  /**************SVG****************/
  
  path.one {
      transition: 0.4s;
      transform: translateX(-60%);
  }
  
  path.two {
      transition: 0.5s;
      transform: translateX(-30%);
  }
  
  .soundcloud-button:hover path.three {
      animation: color_anim 1s infinite 0.2s;
  }
  
  .soundcloud-button:hover path.one {
      transform: translateX(0%);
      animation: color_anim 1s infinite 0.6s;
  }
  
  .soundcloud-button:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: #FBC638;
    }
    100% {
        fill: white;
    }
}

/* MEDIA QUERY */


@media only screen and (max-width: 425px ){
    .foreground{
        scale: 4.25;
        margin-left: 600px;
        margin-top: -370px;
        height: auto;
    }
    .title{
        margin-top: 100px;
        scale: 0.5;
    }
    .text{
        width: 100%;
        letter-spacing: normal;
        line-height: 150%;
    }
    .wrapper-soundcloud-button{
        padding: 0 3rem;
    }
    .soundcloud-button{
        width: 100%;
        height: 100%;
        font-size: 25px;
        line-height: -70px;
        outline: none; 
        transition: 0.5s;
        box-shadow: 10px 10px 0 #FBC638;
    }
     .soundcloud-button span:nth-child(2) {
         transition: 0.5s;
         margin-right: 45px;
     }
     
       .soundcloud-button.span {
         transform: skewX(15deg) 
       }
     
       .soundcloud-button.span:nth-child(2) {
         width: 40px;
         margin-left: 30px;
         position: relative;
         top: 12%;
       }
       
     /**************SVG****************/
     
     path.one {
         transition: 0.4s;
         transform: translateX(1%);
         transform: rotate(-0.5deg);
         animation: color_anim 1s infinite 0.6s;
     }
     
     path.two {
        transform: rotate(-0.5deg);
         transition: 0.5s;
         transform: translateX(2%);
         animation: color_anim 1s infinite 0.4s;
     }
     path.three{
        transform: rotate(-0.5deg);
        transform: translateX(2%);
        animation: color_anim 1s infinite 0.2s;
     }
}