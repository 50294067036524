@keyframes FadeInOut {
  0% {
    -webkit-backface-visibility: hidden;
    background-image: url(../img/Logo-Hellblau.svg);
  }
  25% {
    -webkit-backface-visibility: hidden;
    background-image: url(../img/Logo-Dunkelblau.svg);
  }
  50% {
    -webkit-backface-visibility: hidden;
    background-image: url(../img/Logo-Pink.svg);
  }
  75% {
    -webkit-backface-visibility: hidden;
    background-image: url(../img/Logo-Rot.svg);
  }
  100% {
    -webkit-backface-visibility: hidden;
    background-image: url(../img/Logo-Hellblau.svg);
  }
}
.homeContainer {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  z-index: 1;
}

.cfUnderlayer {
  background-image: url(../img/Logo-Hellblau.svg);
  position: absolute;
  margin-top: -100px;
  min-height: 30vh;
  max-width: 800px;
  width: 100%;
  max-height: 100%;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  z-index: 2;
  overflow-y: hidden;
  overflow-x: hidden;
  opacity: 1;
}

#cf:hover {
  transform: scale(1.1);
  cursor: pointer;
}
#cf {
  position: relative;
  min-height: 800px;
  background-size: auto;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  perspective: 1000;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: FadeInOut 12s infinite;
  animation: FadeInOut 12s infinite;
  animation-delay: 1s;
  -webkit-animation-timing-function: ease-in-out(1, end);
  animation-timing-function: ease-in-out(1, end);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
@media screen and (max-width: 1400px) {
  #cf {
    width: 500px;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
  }
  #cf:hover {
    cursor: pointer;
    transform: scale(1);
    background-repeat: repeat-y;
  }
  .cfUnderlayer {
    margin-top: -120px;
    height: 500px;
  }
}
@media screen and (max-width: 1024px) {
  #cf {
    width: 500px;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
  }
  #cf:hover {
    cursor: pointer;
    transform: scale(1);
  }
  .cfUnderlayer {
    height: 500px;
    width: 500px;
    margin-top: -50px;
  }
}
@media screen and (max-width: 768px) {
  #cf {
    width: 400px;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
  }
  .cfUnderlayer {
    height: 400px;
    width: 400px;
    margin-top: -100px;
  }
}
@media screen and (max-width: 425px) {
  #cf {
    width: 340px;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
  }
  .cfUnderlayer {
    height: 340px;
    width: 340px;
    margin-top: -100px;
  }
}
@media screen and (max-width: 375px) {
  #cf {
    width: 300px;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
  }
  .cfUnderlayer {
    height: 300px;
    width: 300px;
    margin-top: -100px;
  }
}
@media screen and (max-width: 320px) {
  #cf {
    width: 300px;
    margin: 0 auto 0 auto;
    background-repeat: no-repeat;
  }
  .cfUnderlayer {
    height: 300px;
    width: 300px;
    margin-top: -100px;
  }
}



audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: none;
}
audio::-webkit-media-controls,
video::-webkit-media-controls {
  display: none;
}
