.footerContainer{
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}
.black{
    background-color: hsl(0, 0%, 15%);
}
.space-between{
    justify-content: space-between;
}
.footer-logo-container{
    margin: auto;
    width: 20%;
    height: 100%;
}
#footer-logo{
    max-width: 100%;
    max-height: 100%;
    height: 132px;
    width: 132px;
    background: url('../img/Logo-Hellblau.svg') no-repeat;
    background-size: cover;
    margin: auto;
    background-position: 0 -10px;
}
#footer-logo:hover{
    transform: scale(1.1);
    
}
.footer-content-container{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 4rem;
    width: 100%;
    height: 100%;
}

.footer-content-container > div{
    width: 100%;
}
.footer-content-container > div > p,a{
    color: white;
    text-decoration: none;
}
.footer-content-container > div > p {
    font-size: 30px;
}
.footer-content-container > a {
    font-size: 16px;
}
.footer-content-container > a:hover {
    color: green;
    cursor: pointer;
}

@media only screen and (max-width: 425px){
    .footerContainer{
        height: 60px;
    }
    .footer-content-container{
        align-items: stretch;
        padding: 0;
    }
    .footer-content-container > div > p {
        font-size: 18px;
        color: white;
    }
    .footer-content-container > a {
        font-size: 12px;
    }
}