.contactContainer{
    min-height: calc(100vh - 100px);
    width: 100%;
    background: url('../img/backgrounds/psychedUniverse.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactForm{
    height: calc(100% - 130px);
    min-height: calc(100% - 130px);
    width: 50vw;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputStyle, .textStyle{
    border-radius: 10px;
}
.inputStyle{
    padding: 0 1rem;
}
.textStyle{
    padding: 1rem;
}

.smallContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    width: 100%;
}

.bigContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    width: 100%;
}

form{
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
label{
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
}
input{
    display: block;
    width: 80%;
    min-height: 45px;
    font-size: 1.5rem;
    outline: none !important;
    border: 1px solid white;
    background: linear-gradient(to right, #D3CCE3,#E9E4F0);
}
input:focus{
    outline: none !important;
    border: 2px solid rgb(27, 92, 189);
    box-shadow: rgba(255, 255, 255, 0.5) 0px 2px 8px 0px;
}

textarea{
    width: 80%;
    font-size: 1.5rem;
    outline: none !important;
    border: 1px solid white;
    background: linear-gradient(to right, #D3CCE3,#E9E4F0);
}
textarea:focus{
    outline: none !important;
    border: 2px solid rgb(27, 92, 189);
    box-shadow: rgba(255, 254, 254, 0.4) 0px 2px 8px 0px;
}

/* CSS BUTTON */
.glow-on-hover {
    width: 20%;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 1.5rem;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #fff
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}



  @media only screen and (max-width: 425px){
    .contactContainer{
        min-height: calc(100vh - 60px);
        background-position: -200px 0;
        background-size: cover;
        transform: scale(1);
    }
    .glow-on-hover{
        width: 70%;
        height: auto;
    }
    .contactForm{
        width: 90%;
    }
    form{
        width: 100%;
    }
    input{
        width: 100%;
    }
    textarea{
        width: 100%;
    }
}

@media only screen and (max-height: 800px){
    .contactContainer{
        padding-top: 100px;
    }
}